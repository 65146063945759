import React from "react"
import useWindowWidth from "../hooks/useWindowWidth"

const Logo = props => {
  const viewport = useWindowWidth()
  const checkPage = () => {
    if ((props.mostWantedPage && props.searchOpen) || (props.teamPage && props.searchOpen)) {
      return "/logo.svg"
    }
    if (viewport.width <= 640 && props.menuOpen) {
      return "/logo-white.svg"
    } else if (props.mostWantedPage || props.teamPage) {
      return "/logo-white.svg"
    } else {
      return "/logo.svg"
    }
  }
  return <img src={checkPage()} width="171" height="48" alt="Mobile Phone Museum Logo" title="Mobile Phone Museum" />
}
export default Logo
