import { Link } from "gatsby"
import React, { useState, useEffect, useCallback } from "react"
import Nav from "./nav"
import Cookies from "./cookies"
import Logo from "./logo"
import useWindowWidth from "../hooks/useWindowWidth"

const Header = React.memo(props => {
  const viewport = useWindowWidth()
  const [wWidth, setwWidth] = useState(0)
  useEffect(() => {
    setwWidth(viewport.width)
  }, [viewport])

  const isAboutPage = props && props.aboutLocation ? true : false
  const isContactPage = props && props.contactLocation ? true : false

  const [menuOpen, setmenuOpen] = useState(false)
  const toggleMenuKey = ev => {
    if (ev.keyCode === 13) {
      setmenuOpen(!menuOpen)
    }
  }
  const toggleMenu = ev => {
    switchOffOthers("menuOpen")
    setmenuOpen(!menuOpen)
  }

  const switchOffOthers = par => {
    if (par !== "menuOpen") setmenuOpen(false)
  }

  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      setmenuOpen(false)
    }
  }, [])
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)
    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const PageTitle = () => {
    if (isAboutPage || isContactPage) {
      return <div className="pageTitle">{props.title}</div>
    } else {
      return null
    }
  }

  return (
    <>
      <Cookies />
      <header role="banner" style={{ opacity: "0" }}>
        {menuOpen === true && <div id="overLayer" role="button" tabIndex="0" aria-label="open-close menu" onClick={() => setmenuOpen(false)} onKeyDown={toggleMenuKey}></div>}
        <div className="level1">
          <Link activeClassName="active" to="/" className="logo">
            <Logo menuOpen={menuOpen} />
          </Link>

          <PageTitle />

          <div className="rightSide">
            <div
              className={`toggleMenu ${menuOpen ? "open" : ""}`}
              style={{ zIndex: menuOpen ? 999999499 : 9999993 }}
              onClick={toggleMenu}
              onKeyDown={toggleMenuKey}
              tabIndex="0"
              role="button"
              aria-label="Open menu"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span>Menu</span>
            </div>
            <div className={`menuWrapper ${menuOpen ? "visible" : ""}`}>
              {wWidth > 640 ? <div className="menuTop" /> : null}

              <div className={`mainMenu ${menuOpen ? "open" : ""}`} style={{ zIndex: menuOpen ? 999999299 : 9999991 }}>
                <div className="mainMenuInner">
                  <Nav />
                </div>
              </div>
              {wWidth > 640 ? <div className="menuBottom"></div> : null}
            </div>
          </div>
        </div>
      </header>
    </>
  )
})

export default Header
