import { Link } from "gatsby"
import React from "react"

export default () => {
  return (
    <ul id="main-menu" className="menu">
      <li className="groupStart">
        <Link activeClassName="active" to="/">
          Home
        </Link>
      </li>
      <li className="groupStart">
        <Link activeClassName="active" to="/about">
          About
        </Link>
      </li>
      <li className="groupStart">
        <Link activeClassName="active" to="/contact">
          Contact
        </Link>
      </li>
    </ul>
  )
}
