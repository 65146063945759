/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
function SEO({ description, lang, meta, title, bodyClass }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const pageTitle = title || site.siteMetadata.title

  React.useEffect(() => {
    if (process.env.GATSBY_DIRECTUS_URL && process.env.GATSBY_DIRECTUS_URL.indexOf("mobilephonemuseum.com") === -1) {
      ;(function (d) {
        var s = d.createElement("script")
        /* uncomment the following line to override default position*/
        s.setAttribute("data-position", 5)
        /* uncomment the following line to override default size (values: small, large)*/
        s.setAttribute("data-size", "small")
        /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
        /* s.setAttribute("data-language", "null");*/
        /* uncomment the following line to override color set via widget (e.g., #053f67)*/
        s.setAttribute("data-color", "#2c2c2c")
        /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
        s.setAttribute("data-type", "1")
        /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
        /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility";*/
        /* uncomment the following line to override support on mobile devices*/
        /* s.setAttribute("data-mobile", true);*/
        /* uncomment the following line to set custom trigger action for accessibility menu*/
        /* s.setAttribute("data-trigger", "triggerId")*/
        s.setAttribute("data-account", "1Bw81j9bxk")
        s.setAttribute("src", "https://cdn.userway.org/widget.js")
        ;(d.body || d.head).appendChild(s)
      })(document)
    }
  }, [])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title : pageTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {" "}
      <body className={bodyClass ? bodyClass : ""} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `We are a not-for-profit entity created to safeguard the rich history and design diversity of mobile devices which have been launched over the last four decades. `,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
