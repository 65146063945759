import React from "react"
import { useForm } from "react-hook-form"
import Header from "../components/header"
import SEO from "../components/seo"
//import { graphql } from "gatsby"
//import { Helmet } from "react-helmet"
var Recaptcha = require("react-recaptcha")

const Contact = props => {
  // const { data } = props
  //const { nodes } = data.allDirectusPage
  // const nodes = []
  //const page = nodes.length > 0 ? nodes[0] : {}
  const [isHuman, setisHuman] = React.useState(false)
  const [submitted, setsubmitted] = React.useState(false)
  const isClient = typeof window === "object" ? true : false
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => {
    if (isHuman) {
      console.log(data)
      const submitNow = async () => {
        console.log("submitNowing")
        try {
          const response = await fetch("/api/contact_form", {
            method: "post",
            body: JSON.stringify(data),
          })
          const responseString = await response.json()
          console.log(responseString)
          setsubmitted(true)
        } catch (e) {
          console.warn(e)
        }
      }
      submitNow()
    } else {
      console.log("Human not verified.")
    }
  }
  const verifyCallback = function (response) {
    //console.log(response)
    setisHuman(true)
  }
  return (
    <div className="contact">
      <SEO title="Contact" />
      {/*       <Helmet>
        <script
          type="text/javascript"
          src="https://platform-api.sharethis.com/js/sharethis.js#property=60355ef55b508c00110259fc&product=sticky-share-buttons"
          async="async"
        ></script>
      </Helmet> */}
      <Header title="Contact" contactLocation={true} />
      <div className="twoPanes">
        <div class="pane1">
          <div class="content">
            <p>The Mobile Phone Museum would love to hear from you. We're happy to answer any questions you may have.</p>
            <div>
              <h2 style={{ marginBottom: "0.5rem", color: "black", fontFamily: "Galano-Grotesque-Bold" }}>Launch media enquires:</h2>
              <p style={{ color: "black", marginBottom: "0", fontFamily: "Galano-Grotesque-Bold" }}>Mobilephonemuseum@</p>
              <p style={{ color: "black", fontFamily: "Galano-Grotesque-Bold" }}>Vodafone@</p>
            </div>
            <p>
              <em>
                <small>
                  By submitting your personal data you agree that the Mobile Phone Museum can use your data in accordance with our{" "}
                  <a title="View our privacy policy" href="https://drive.google.com/file/d/18fsdVqs8vyabAHqBKKkwFXqwVIx5Ca3m/view" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .
                </small>
              </em>
            </p>
            <p>
              Email:&nbsp;
              <a href="mailto:hello@mobilephonemuseum.com?subject=Hello!" target="_blank" rel="noopener noreferrer">
                hello@mobilephonemuseum.com
              </a>
            </p>
            <p>
              Twitter:{" "}
              <a title="Link to Mobile Phone Museum Twitter page" href="https://twitter.com/Phone_Museum" target="_blank" rel="noopener noreferrer">
                {" "}
                @Phone_Museum{" "}
              </a>
            </p>
            <p>
              Instagram:{" "}
              <a title="Link to Mobile Phone Museum Instagram page" href="https://www.instagram.com/MobPhoneMuseum/" target="_blank" rel="noopener noreferrer">
                {" "}
                @MobPhoneMuseum
              </a>
            </p>
            <p>
              LinkedIn:{" "}
              <a title="Link to Mobile Phone Museum LinkedIn Page" href="https://www.linkedin.com/company/mob-phone-museum/" target="_blank" rel="noopener noreferrer">
                Mobile Phone Museum
              </a>
            </p>
          </div>
        </div>
        <div className="pane2">
          {submitted && (
            <>
              <h2 className="h3">Thank you for getting in touch.</h2>
              <p>We'll get back to you shortly.</p>
            </>
          )}
          {!submitted && (
            <>
              <h2 className="h3">Got a Question? Have a Phone to Donate? </h2>
              <p>Leave your details below and we'll be in touch.</p>
              <br />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="m-form-group">
                  <label htmlFor="name">
                    Name
                    <span className="asterix">*</span>
                  </label>
                  <div className="bottomBorder">
                    <input ref={register({ required: true })} type="text" name="name" id="name" className="a-form-input" />
                  </div>
                  {/* <p className="a-form-description">(please complete only if you want to be informed in detail about the progress and results)</p> */}
                  {errors.name && <p className="a-form-error">This field is required.</p>}
                </div>
                <div className="m-form-group">
                  <label htmlFor="email">
                    E-mail
                    <span className="asterix">*</span>
                  </label>
                  <div className="bottomBorder">
                    <input ref={register({ required: true })} type="email" name="email" id="email" className="a-form-input" />
                  </div>
                  {/* <p className="a-form-description">(please complete only if you want to be informed in detail about the progress and results)</p> */}
                  {errors.email && <p className="a-form-error">This field is required.</p>}
                </div>
                <div className="m-form-group">
                  <label htmlFor="subject">
                    Subject
                    <span className="asterix">*</span>
                  </label>
                  <div className="bottomBorder">
                    <input ref={register({ required: true })} type="text" name="subject" id="subject" className="a-form-input" />
                  </div>
                  {/* <p className="a-form-description">(please complete only if you want to be informed in detail about the progress and results)</p> */}
                  {errors.subject && <p className="a-form-error">This field is required.</p>}
                </div>
                <div className="m-form-group">
                  <label htmlFor="message">
                    Message
                    <span className="asterix">*</span>
                  </label>
                  <div className="bottomBorder">
                    <textarea ref={register({ required: true })} name="message" id="message" className="a-form-textarea"></textarea>
                  </div>
                  {errors.message && (
                    <p id="message_message" className="a-form-error">
                      This field is required.
                    </p>
                  )}
                </div>
                <div className="m-form-group recaptcha">
                  {isClient && (
                    <Recaptcha
                      sitekey={
                        process.env.GATSBY_DIRECTUS_URL && process.env.GATSBY_DIRECTUS_URL.indexOf("mobilephonemuseum.com") > -1
                          ? "6LewhmcaAAAAAIl3TlwRQ6aR3s6VYDvijIwkanU6"
                          : "6LexOUsaAAAAAKwAvV2r-9eyNM96KKpMZkUCnvu3"
                      }
                      verifyCallback={verifyCallback}
                    />
                  )}
                </div>
                <input type="submit" className="button" value="Submit" />
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

/* export const query = graphql`
  query {
    allDirectusPage(filter: { id: { eq: "Directus__Page__8" } }) {
      nodes {
        id
        title
        content
        image {
          localFile {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
` */

export default Contact
